/* eslint-disable react/jsx-no-target-blank */
import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

interface HeaderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawData: any
}

const Header = (props: HeaderProps): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "document-eSign-logo.svg" }) {
        publicURL
      }
    }
  `)
  const headerClass =
    props && props.rawData && props.rawData.className
      ? `fixed-top ${props.rawData.className}`
      : `fixed-top`
  return (
    <header className={headerClass} id="header">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={data?.logo?.publicURL}
              width="250"
              height="39"
              alt="Document eSign"
              loading="lazy"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse main-nav"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav align-items-lg-center d-lg-flex">
              <li className="nav-item">
                <Link to="/features/" className="nav-link">
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/pricing/" className="nav-link">
                  How it&apos;s free?
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact/" className="nav-link">
                  Contact
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav  ml-lg-auto d-lg-flex">
              <li className="nav-item">
                <a
                  href="https://app.documentesign.com/auth/login"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link"
                >
                  Sign In
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://app.documentesign.com/auth/signup"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary ml-md-3 d-block d-md-inline-block my-3 my-lg-0"
                >
                  Sign up free
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  rawData: PropTypes.any,
}

Header.defaultProps = {
  siteTitle: ``,
  rawData: null,
}

export default Header
