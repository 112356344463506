/* eslint-disable react/jsx-no-target-blank */
import { Link, withPrefix } from "gatsby"
import React from "react"

const Footer = (): JSX.Element => {
  return (
    <footer>
      <div className="action-ribbon py-5">
        <div className="container">
          <div className="d-lg-flex align-items-lg-center">
            <div className="flex-lg-grow-1 ani-in show-on-scroll">
              <h2 className="mt-4 mb-3">Start using Document eSign today!</h2>
              <p>
                Easy, secure and simple way to electronically sign documents
                online
                <br /> anytime, anywhere on any devices with Document eSign.
              </p>
            </div>
            <div className="flex-lg-shrink-0 text-lg-center ani-in show-on-scroll">
              <a
                href="https://app.documentesign.com/auth/signup"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-inverse-primary mt-2 mb-2 px-md-5"
              >
                Get started
              </a>
              <div>
                <small className="text-white opacity-5">
                  <strong>Free forever,</strong> No credit card required
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container  py-5">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4">
            <div className="col footer-link pb-3">
              <div className="h6">General</div>
              <Link to="/features/">Features</Link>
              <Link to="/pricing/">How it&apos;s free?</Link>
              <Link to="/blog/">Blog</Link>
              <Link to="/terms-and-conditions/">Terms and Conditions</Link>
              <Link to="/privacy-policy/">Privacy policy</Link>
              <Link to="/contact/">Contact</Link>
            </div>
            <div className="col footer-link pb-3">
              <div className="h6">Features</div>
              <Link to="/document-signing/">Document Signing</Link>
              <Link to="/document-workflows/">Document Workflows</Link>
              <Link to="/reusable-templates/">Reusable Templates</Link>
              <Link to="/document-sharing-tracking/">Sharing and Tracking</Link>
              <Link to="/api-integration/">API Integration</Link>
              <Link to="/document-auditing/">Document Auditing</Link>
              <Link to="/pdf-conversion/">eSign PDF Conversion</Link>
              <Link to="/storage-integration/">Cloud Storage Integration</Link>
            </div>
            <div className="col footer-link pb-3">
              <div className="h6">Explore use cases</div>
              <Link to="/finance/">Banking and Finance</Link>
              <Link to="/human-resource/">Human Resources</Link>
              <Link to="/legal/">Legal Processes</Link>
              <Link to="/real-estate/">Real Estate</Link>
              <Link to="/free-pdf-esign-online/">Free Pdf eSign Online</Link>
              <Link to="/free-document-esign-online/">
                Free Document eSign Online
              </Link>
            </div>
            <div className="col pb-3">
              <img
                className="lazyload"
                data-src={withPrefix("images/greenLogo.png")}
                width="150"
                alt="Go green"
              />
              <div className="mt-3 text-footer">
                Go green with electronic signatures &amp; reduce your paper
                footprints.
              </div>
              <div className="social-media py-3">
                <a
                  href="https://www.facebook.com/documentesign/"
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://twitter.com/documentesign"
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/documentesign/"
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                  href="https://in.pinterest.com/documentesignn/"
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                >
                  <i className="fab fa-pinterest-p"></i>
                </a>
                <a
                  href="https://medium.com/@documentesign"
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                >
                  <i className="fab fa-medium-m"></i>
                </a>
                <a
                  href="https://instagram.com/documentesign"
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
              <div className="text-footer">
                Copyright © 2021{" "}
                <a
                  href="https://www.angularminds.com/"
                  target="_blank"
                  className="text-footer"
                >
                  {" "}
                  Angular Minds
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
