/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
interface LayoutProps {
  children?: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawData?: any
}

const Layout = ({ children, rawData }: LayoutProps): JSX.Element => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={
          data.site.siteMetadata?.title ||
          `Document ESign - Free Online ESignature Services`
        }
        rawData={rawData}
      />
      <>
        <main>{children}</main>
      </>
      <Footer />
    </>
  )
}

export default Layout
