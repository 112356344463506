import { useEffect } from "react"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useScript = url => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = url

    script.async = false

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

export default useScript
